import {
  MeasurableConditional,
  MeasurableConditionalValue,
  MeasurableInterval,
  MeasurableIntervalValue,
  MeasurableTrend,
  MeasurableTrendValue,
  MeasurableType,
  MeasurableTypeValue,
} from './types';

export { default as ADD_MEASURABLE } from './add-measurable';
export { default as GET_MEASURABLE } from './get-measurable';
export { default as GET_MEASURABLES } from './get-measurables';
export { default as UPDATE_MEASURABLE } from './update-measurable';
export { default as UPDATE_MEASURABLES } from './update-measurables';
export { default as ADD_MEASURABLES } from './add-measurables';

export const MEASURABLE_INTERVALS: MeasurableInterval[] = [
  { value: 'all', label: 'All' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'annually', label: 'Annually' },
];

export const MEASURABLE_TYPES: MeasurableType[] = [
  { value: 'currency', label: 'Currency', symbol: '$' },
  { value: 'percentage', label: 'Percentage', symbol: '%' },
  { value: 'number', label: 'Number', symbol: '' },
];

export const MEASURABLE_CONDITIONALS: MeasurableConditional[] = [
  { value: 'gte', label: '>=' },
  { value: 'gt', label: '>' },
  { value: 'eq', label: '=' },
  { value: 'lt', label: '<' },
  { value: 'lte', label: '<=' },
  { value: 'range', label: 'range' },
];

export const MEASURABLE_TRENDS: MeasurableTrend[] = [
  { value: 'average', label: 'Average' },
  { value: 'total', label: 'Total' },
];

export function getConditional(conditional: MeasurableConditionalValue): MeasurableConditional {
  return MEASURABLE_CONDITIONALS.find(({ value }) => value === conditional);
}

export function getInterval(interval: MeasurableIntervalValue): MeasurableInterval {
  return MEASURABLE_INTERVALS.find(({ value }) => value === interval);
}

export function getType(type: MeasurableTypeValue): MeasurableType {
  return MEASURABLE_TYPES.find(({ value }) => value === type);
}

export function getTrend(trend: MeasurableTrendValue): MeasurableTrend {
  return MEASURABLE_TRENDS.find(({ value }) => value === trend);
}

export const DEFAULT_INTERVAL = 'weekly';
