import { captureException } from '@sentry/nextjs';
import { Dialog, Listbox, Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronRightIcon, SelectorIcon, XIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, RichTextEditor, SuccessToast, MeasurableAdditionalInfo } from 'components';
import { ADD_ACTIONS } from 'data/actions';
import { useTeamMembers } from 'data/hooks';
import { ADD_OBJECTIVE } from 'data/objectives';
import { ADD_OPPORTUNITY, OPPORTUNITY_TYPE_OPTIONS } from 'data/opportunities';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, useQuery } from 'urql';
import { classNames, getPluralEntity, refreshData } from 'utils';
import { ADD_MEASURABLE, DEFAULT_INTERVAL } from 'data/measurables';
import { MeasurableAdditionalDetail } from 'data/measurables/types';
import { filterUsersByRoles } from 'utils/filterUsers';
import { RoleTypes } from 'components/UsersList/UsersList';
import { OpportunityTypeValue } from 'data/opportunities/types';
import { getOpportunityTypeLabel } from 'components/OpportunityForm/OpportunityForm';
import GET_WEBHOOKS_BY_USER_AND_TYPE from 'data/actions/get-webhooks-by-user-and-type';
import notifyZapier, { ActionRequestBody, Webhook } from 'utils/notifyWebhooks';

export type EntityType = 'objective' | 'action' | 'opportunity' | 'measurable';

export const ENTITY_TYPE_LABELS = {
  action: 'Action',
  objective: 'Objective',
  opportunity: 'Opportunity',
  measurable: 'Measurable',
};

type QuickAddDialogProps = {
  entityType: EntityType;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function QuickAddDialog({ entityType, open, setOpen }: QuickAddDialogProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [teamMembers] = useTeamMembers(router.query.team as string);

  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();

  const [entity, setEntity] = useState<EntityType>(entityType);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [owner, setOwner] = useState(Number(session.id));
  const [opportunityType, setOpportunityType] = useState<OpportunityTypeValue>('na');

  const users = useMemo(() => teamMembers.map(({ company_user }) => company_user), [teamMembers]);
  const selectedOwner = filterUsersByRoles(users, [RoleTypes.ADMIN, RoleTypes.MEMBER]).find(
    ({ user }) => user.id === owner,
  )?.user;

  const [, addActions] = useMutation(ADD_ACTIONS);
  const [, addObjective] = useMutation(ADD_OBJECTIVE);
  const [, addOpportunity] = useMutation(ADD_OPPORTUNITY);
  const [, addMeasurable] = useMutation(ADD_MEASURABLE);

  const [actionWebhookResults, refetchActionWebhooks] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: session.id,
      trigger_type: 'action',
    },
  });

  const [objectiveWebhookResult, refetchObjectiveWebhooks] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: session.id,
      trigger_type: 'objective',
    },
  });

  const [actionAssigned, refetchActionAssgned] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: owner,
      trigger_type: 'action-assigned',
    },
    pause: !owner,
  });

  const [objectiveAssigned, refetchObjectiveAssigned] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: owner,
      trigger_type: 'objective-assigned',
    },
    pause: !owner,
  });

  useEffect(() => {
    if (owner) {
      refetchActionAssgned();
      refetchObjectiveAssigned();
    }
  }, [owner]);

  const [actionAssignees, setActionAssignees] = useState({ assignees: [] });
  const assigneeIds = actionAssignees?.assignees?.map((company_user) => company_user?.user_id);

  function toggleAssignees(_users) {
    let _assignees = actionAssignees?.assignees || [];
    _users.forEach((_user) => {
      if (_assignees.map((company_user) => company_user.user_id).includes(_user.id)) {
        _assignees = _assignees.filter((company_user) => company_user.user_id !== _user.id);
      } else {
        _assignees.push(users.find(({ user_id }) => user_id === _user.id));
      }
    });
    setActionAssignees({
      ...actionAssignees,
      assignees: _assignees,
    });
  }

  const cancelButtonRef = useRef(null);

  const queryString = Object.entries({ title, description, owner })
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const [measurableAdditionalInfo, setMeasurableAdditionalInfo] = useState<MeasurableAdditionalDetail>({
    interval: DEFAULT_INTERVAL,
    archived: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const createDisabled = Boolean(Object.values(errors).filter(Boolean).length);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    if (createDisabled) return;

                    let ownerKey = 'owner';
                    const date = new Date();
                    date.setDate(date.getDate() + 7);
                    const object = {
                      title,
                      description,
                      company_id: router.query.company,
                      teams_id: router.query.team,
                    };
                    let mutation = addObjective;
                    switch (entity) {
                      case 'action':
                        ownerKey = 'assignee';
                        object['date'] = date.toISOString().slice(0, 10);
                        (object['actions_activities'] = {
                          data: {
                            activity: 'insert',
                            user_id: session.id,
                            timestamp: Date.now(),
                          },
                        }),
                          (mutation = addActions);
                        break;
                      case 'opportunity':
                        ownerKey = 'assignee';
                        object['type'] = opportunityType;
                        object['opportunities_activities'] = {
                          data: {
                            activity: 'insert',
                            user_id: session.id,
                            timestamp: Date.now(),
                          },
                        };
                        mutation = addOpportunity;
                        break;
                      case 'measurable':
                        Object.assign(object, measurableAdditionalInfo);
                        mutation = addMeasurable;
                        break;
                      case 'objective':
                      default:
                        break;
                    }
                    object[ownerKey] = owner;
                    const actionList = [object];

                    // Copy action to multiple assignees
                    if (entity === 'action') {
                      actionAssignees?.assignees.forEach((assignee) => {
                        const action = {
                          ...object,
                          assignee: assignee?.user_id,
                        };
                        actionList.push(action);
                      });

                      const parsedDescription = JSON.parse(object.description) || '';
                      const descriptionText = parsedDescription.blocks[0].text;

                      if (actionWebhookResults.data && actionWebhookResults.data?.zapier_webhooks.length > 0) {
                        const wh: Webhook[] = actionWebhookResults.data?.zapier_webhooks;
                        const actionRequestBody = {
                          title: title || '',
                          owner: selectedOwner.name || '',
                          description: descriptionText,
                          date: '',
                        };
                        const res = await notifyZapier(wh, actionRequestBody);
                      }

                      if (
                        actionAssigned.data &&
                        actionAssigned.data?.zapier_webhooks.length > 0 &&
                        actionAssigned.data?.zapier_webhooks[0].user_id === owner
                      ) {
                        const wh: Webhook[] = actionAssigned.data?.zapier_webhooks;
                        const actionRequestBody = {
                          title: title || '',
                          owner: selectedOwner.name || '',
                          description: descriptionText,
                          date: '',
                        };
                        await notifyZapier(wh, actionRequestBody);
                      }
                    }

                    if (entity === 'objective') {
                      const parsedDescription = JSON.parse(object.description) || '';
                      const descriptionText = parsedDescription.blocks[0].text;
                      if (
                        objectiveWebhookResult.data &&
                        objectiveWebhookResult.data?.zapier_webhooks.length > 0 &&
                        objectiveWebhookResult.data?.zapier_webhooks[0].user_id === owner
                      ) {
                        const wh: Webhook[] = objectiveWebhookResult.data?.zapier_webhooks;
                        const objectiveRequestBody = {
                          title: title || '',
                          owner: selectedOwner.name || '',
                          description: descriptionText,
                          keyResults: '',
                          milestone30Day: '',
                          milestone60Day: '',
                          dueDate: '',
                          status: '',
                        };
                        await notifyZapier(wh, objectiveRequestBody);
                      }

                      if (
                        objectiveAssigned?.data &&
                        objectiveAssigned?.data.zapier_webhooks?.length > 0 &&
                        Number(session?.id) === selectedOwner.id
                      ) {
                        const owh: Webhook[] = objectiveAssigned.data?.zapier_webhooks;
                        const objectiveRequestBody = {
                          title: title || '',
                          owner: selectedOwner.name || '',
                          description: descriptionText,
                          keyResults: '',
                          milestone30Day: '',
                          milestone60Day: '',
                          dueDate: '',
                          status: '',
                        };
                        await notifyZapier(owh, objectiveRequestBody);
                      }
                    }

                    const data = entity === 'action' ? { objects: actionList } : { object };
                    const { error: err } = await mutation(data);

                    if (err) {
                      setError(err);
                      captureException(err);
                    } else {
                      setUpdated(`${ENTITY_TYPE_LABELS[entity]} added`);

                      setEntity(entityType);
                      setTitle(null);
                      setDescription(null);
                      setOwner(Number(session.id));
                      setActionAssignees({
                        ...actionAssignees,
                        assignees: [],
                      });
                      setMeasurableAdditionalInfo({ interval: DEFAULT_INTERVAL, archived: false });
                    }

                    setOpen(false);
                    refreshData(router);
                  }}
                >
                  <div className="flex items-center justify-between p-6 w-full border-b border-gray-300">
                    <div className="text-gray-900 flex items-center">
                      <Listbox value={entity} onChange={setEntity}>
                        <Listbox.Label className="text-md font-semibold text-gray-700 mr-2">New</Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative border border-gray-300 rounded-md shadow-sm w-36 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                            <span className="truncate">{ENTITY_TYPE_LABELS[entity]}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute bg-white z-20 mt-1 shadow-lg max-h-60 w-36 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              {Object.keys(ENTITY_TYPE_LABELS).map((_entityType) => (
                                <Listbox.Option
                                  key={_entityType}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'text-white bg-primary' : 'text-gray-900',
                                      'flex justify-between cursor-default select-none relative py-2 pl-3',
                                    )
                                  }
                                  value={_entityType}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected ? 'font-semibold' : 'font-normal',
                                          'block truncate',
                                        )}
                                      >
                                        {ENTITY_TYPE_LABELS[_entityType]}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-primary',
                                            'inset-y-0 flex items-center pr-3',
                                          )}
                                        >
                                          <CheckIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 right-0 h-6 w-6"
                      onClick={() => {
                        setOpen(false);
                        setTitle(null);
                        setDescription(null);
                        setMeasurableAdditionalInfo({ interval: DEFAULT_INTERVAL, archived: false });
                        setErrors({});
                      }}
                    />
                  </div>
                  <div className="flex flex-col p-6">
                    <div>
                      <label htmlFor="title" className="block text-xs text-gray-700">
                        Title
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          value={title}
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-300 rounded-md"
                          placeholder={`Name the ${entity}`}
                          onChange={(event) => setTitle(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="description" className="block text-xs text-gray-700">
                        Description
                      </label>
                      <div className="mt-1">
                        <RichTextEditor
                          text={description}
                          onChange={(content) => {
                            setDescription(content);
                          }}
                        />
                      </div>
                    </div>
                    {entity === 'measurable' ? (
                      <MeasurableAdditionalInfo
                        errors={errors}
                        setErrors={setErrors}
                        measurableAdditionalInfo={measurableAdditionalInfo}
                        setMeasurableAdditionalInfo={setMeasurableAdditionalInfo}
                      />
                    ) : null}
                    {entity === 'opportunity' ? (
                      <div className="mt-6">
                        <Listbox value={opportunityType} onChange={(opportunity) => setOpportunityType(opportunity)}>
                          {({ open: _openType }) => (
                            <>
                              <Listbox.Label className="block text-xs text-gray-700">Type</Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white(),
                                    'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                                  )}
                                >
                                  {opportunityType ? (
                                    <span className="flex items-center">
                                      {getOpportunityTypeLabel(opportunityType)}
                                    </span>
                                  ) : (
                                    <span className="flex items-center text-gray-500">Select a type</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={_openType}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {OPPORTUNITY_TYPE_OPTIONS.map(({ label, value }) => (
                                      <Listbox.Option
                                        key={value}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={value}
                                      >
                                        <>
                                          <div className="flex items-center">{label}</div>
                                        </>
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                    ) : null}
                    <div className="mt-6">
                      <Listbox
                        value={owner}
                        onChange={(value) => {
                          setOwner(value);
                        }}
                      >
                        {({ open: _open }) => (
                          <>
                            <Listbox.Label className="block text-xs text-gray-700">Owner</Listbox.Label>
                            <div className="mt-1 relative">
                              <Listbox.Button
                                className={classNames(
                                  button.white(),
                                  'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                                )}
                              >
                                {selectedOwner ? (
                                  <span className="flex items-center">
                                    <Avatar user={selectedOwner} showName={true} />
                                  </span>
                                ) : (
                                  <span className="flex items-center text-gray-500">Select a user</span>
                                )}
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={_open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {filterUsersByRoles(users, [RoleTypes.ADMIN, RoleTypes.MEMBER]).map(({ user }) => (
                                    <Listbox.Option
                                      key={user?.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-gray-100' : '',
                                          'cursor-default select-none relative py-2 pl-3 pr-9',
                                        )
                                      }
                                      value={user?.id}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <div className="flex items-center">
                                            <Avatar user={user} showName={true} />
                                          </div>

                                          {selected ? (
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    {entity === 'action' ? (
                      <>
                        <div className="w-full flex mt-8 border-t border-gray-300">
                          <div className="flex flex-col flex-1">
                            <label htmlFor="assignees" className="block text-xs text-gray-700 mt-8">
                              Duplicate for Additional Assignees(Optional)
                            </label>
                            <Menu>
                              <div className="mt-1">
                                <Menu.Button className="w-full rounded-md px-4 py-2 h-12 text-left cursor-default text-sm inline-flex justify-between items-center border border-gray-300 shadow-sm bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500 ">
                                  Select Team Members
                                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>
                              <div className="z-10">
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="absolute mt-2 mb-6 max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {users.map(({ user }) => (
                                      <Menu.Item key={user?.id}>
                                        {({ active }) => (
                                          <div
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                              'cursor-pointer select-none relative py-2 pl-3 pr-9',
                                            )}
                                            onClick={() => {
                                              toggleAssignees([user]);
                                            }}
                                          >
                                            <div className="flex items-center mr-2">
                                              <Avatar user={user} showName={true} />
                                            </div>

                                            {assigneeIds.includes(user?.id) ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </div>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </div>
                            </Menu>
                          </div>
                        </div>
                        <div className="w-full flex mt-4 flex-wrap -m-2">
                          {actionAssignees?.assignees?.map((company_user) => (
                            <div
                              key={company_user?.user?.email}
                              className="m-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <Avatar user={company_user?.user} showName={true} />
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  toggleAssignees([company_user?.user]);
                                }}
                              >
                                <XIcon height="16" width="16" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    {/* TODO: Will probably want to eventually move this away from query string and into React Context */}
                    {entity !== 'measurable' ? (
                      <Link
                        href={`/company/${router.query.company}/team/${router.query.team}/${getPluralEntity(
                          entity,
                        )}/new${queryString.length ? `?${queryString}` : ''}`}
                        className="flex items-center underline text-sm text-primary"
                      >
                        <span className="whitespace-nowrap">Fill out additional details</span>
                        <span className="no-underline">
                          <ChevronRightIcon className="h-5 w-5 inline" />
                        </span>
                      </Link>
                    ) : null}
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                      )}
                      onClick={() => {
                        setOpen(false);
                        setTitle(null);
                        setDescription(null);
                        setMeasurableAdditionalInfo({ interval: DEFAULT_INTERVAL, archived: false });
                        setErrors({});
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary({ isDisabled: createDisabled }),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                      disabled={createDisabled}
                    >
                      <CheckIcon className="h-5 w-5 mr-2" />
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
