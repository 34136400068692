import { captureException } from '@sentry/nextjs';
import { Menu, Transition, Popover } from '@headlessui/react';
import { DotsVerticalIcon, PlusIcon } from '@heroicons/react/outline';
import { Loader } from 'components';
import Avatar from 'components/Avatar';
import { FloatingActionButton, FloatingActionButtons } from 'components/FloatingActionButton';
import NewScoreDialog, { getIntervalDate } from 'components/NewScoreDialog/NewScoreDialog';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { getConditional, UPDATE_MEASURABLE, UPDATE_MEASURABLES } from 'data/measurables';
import { Measurable } from 'data/measurables/types';
import { isSameMonth, isSameQuarter, isSameWeek, isSameDay, parseISO, isSameYear } from 'date-fns';
import { useRouter } from 'next/router';
import {
  getAverage,
  getScores,
  getTotal,
  getValueString,
} from 'pages/company/[company]/team/[team]/scoreboard/[measurable]';
import { Fragment, ReactElement, useState, useRef, useEffect } from 'react';
import { useMutation } from 'urql';
import { classNames, getScoreColor, refreshData } from 'utils';
import { CompanyUser } from 'components/UsersList/UsersList';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { WGAssessment } from 'components';
import Image from 'next/image';

type MeasurablesListProps = {
  measurables: Measurable[];
  measurableActions?: {
    label: string;
    action: (measurable: Measurable) => void;
  }[];
  editable?: boolean;
  isArchivedList?: boolean;
  users?: CompanyUser[];
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
  interval?: string;
  week_start_on?: number;
  selectedMeasurables?: number[];
  setSelectedMeasurables?;
};

const INTERVAL_COUNT = 6;

export default function MeasurablesList({
  measurables,
  measurableActions,
  editable,
  isArchivedList,
  users,
  profileData,
  interval,
  week_start_on,
  selectedMeasurables,
  setSelectedMeasurables,
}: MeasurablesListProps): ReactElement {
  const router = useRouter();
  const [sortedMeasurables, setSortedMeasurables] = useState(measurables);
  const [updatingSorting, setUpdatingSorting] = useState(false);
  const [editingScore, setEditingScore] = useState(false);
  const [scoreToEdit, setScoreToEdit] = useState(null);
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [, updateMeasurable] = useMutation(UPDATE_MEASURABLE);
  const [, updateMeasurables] = useMutation(UPDATE_MEASURABLES);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [showTrend, setShowTrend] = useState<boolean>(true);

  const measurableIds = measurables?.map(({ id }) => id) || [];
  const currentSelectedMeasurables = selectedMeasurables?.filter((id) => {
    return measurableIds.includes(id);
  });

  useEffect(() => {
    setSortedMeasurables(measurables);
  }, [measurables]);

  const intervals = Array(INTERVAL_COUNT)
    .fill(null)
    .map((now, i) => getIntervalDate(interval as string, i, new Date(), week_start_on));

  const dataColumns = intervals.map(({ start, strVal }) => (
    <th
      key={start.toString()}
      scope="col"
      className="px-5 py-3 text-right text-xs font-semibold text-gray-500 uppercase tracking-wider"
    >
      {strVal}
    </th>
  ));
  const data = (measurable: Measurable) => {
    let isSameFn;
    switch (measurable.interval) {
      case 'monthly':
        isSameFn = isSameMonth;
        break;
      case 'quarterly':
        isSameFn = isSameQuarter;
        break;
      case 'annually':
        isSameFn = isSameYear;
        break;
      case 'daily':
        isSameFn = isSameDay;
        break;
      case 'weekly':
      default:
        isSameFn = isSameWeek;
        break;
    }
    return intervals.map(({ start, strStart }) => {
      const scores = getScores(measurable);
      const score = scores.find(({ date }) => {
        return date && isSameFn(parseISO(date), parseISO(strStart));
      });
      const color = getScoreColor(
        score?.value,
        measurable.goal,
        measurable.conditional,
        measurable?.min_range,
        measurable?.max_range,
      );
      return (
        <td
          key={start.toString()}
          className={`h-12 px-6 whitespace-nowrap ${color.bg}`}
          onClick={
            editable
              ? (event) => {
                  event.stopPropagation();

                  setEditingScore(true);
                  setScoreToEdit({ score: score, measurable, date: start });
                }
              : null
          }
        >
          <div className={`flex items-center justify-end ${color.fg}`}>
            {score?.value || score?.value === 0 ? (
              score.value?.toLocaleString()
            ) : editable ? (
              <PlusIcon className="h-4 w-4 stroke-2" />
            ) : (
              ''
            )}
          </div>
        </td>
      );
    });
  };

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  const dragMeasurable = useRef<number>(0);
  const draggedOverMeasurable = useRef<number>(0);

  const handleMeasurableSort = () => {
    setUpdatingSorting(true);
    const measurablesClone = [...sortedMeasurables];
    // following is replacement sort logic - not correct
    // const temp = measurablesClone[dragMeasurable.current];
    // measurablesClone[dragMeasurable.current] = measurablesClone[draggedOverMeasurable.current];
    // measurablesClone[draggedOverMeasurable.current] = temp;

    // following is insertion sort logic - which is what client wanted
    const temp = measurablesClone[dragMeasurable.current];
    measurablesClone.splice(dragMeasurable.current, 1);
    measurablesClone.splice(draggedOverMeasurable.current, 0, temp);

    setSortedMeasurables(measurablesClone);
    // TODO: update measurable order after updating
    measurablesClone.map(async (measurable, index) => {
      const { error: editError } = await updateMeasurable({
        _set: {
          sort_order: index,
        },
        id: measurable.id,
      });
      if (!editError) {
        setUpdatingSorting(false);
      }
    });
  };

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th></th>
                  {editable ? (
                    <th scope="col" className="pl- w-8">
                      <input
                        type="checkbox"
                        checked={currentSelectedMeasurables.length === measurables.length}
                        className="rounded cursor-pointer"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedMeasurables(selectedMeasurables.concat(measurables.map(({ id }) => id)));
                          } else {
                            setSelectedMeasurables(selectedMeasurables.filter((_id) => !measurableIds?.includes(_id)));
                          }
                        }}
                      ></input>
                    </th>
                  ) : null}
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Goal
                  </th>
                  {showTrend ? (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                      onClick={() => setShowTrend(false)}
                    >
                      Trend <span className="font-bold text-black">&gt;&lt;</span>
                    </th>
                  ) : (
                    <th onClick={() => setShowTrend(true)}>&lt;&gt;</th>
                  )}

                  {dataColumns}
                  <th scope="col" className="relative px-6 py-3 w-16">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              {updatingSorting ? (
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td colSpan={8} className="h-12 px-6 whitespace-nowrap">
                      <Loader color="text-black" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="divide-y divide-gray-200">
                  {sortedMeasurables.map((measurable, index) => {
                    const { id, title, conditional, trend, type, goal, user, min_range, max_range } = measurable;
                    const wgSkills = users.find((_user) => _user.user_id === user?.id)?.wg_skills;
                    const vopsSkills = users.find((_user) => _user.user_id === user?.id)?.vops_skills;
                    const vopsValue = getVOPSValue(vopsSkills);
                    const average = getAverage(measurable);

                    const min = getValueString(min_range, type);
                    const max = getValueString(max_range, type);
                    const goalRange = min && max ? min + ' - ' + max : null;

                    let trendValue;
                    switch (trend) {
                      case 'total':
                        trendValue = getTotal(measurable);
                        break;
                      case 'average':
                      default:
                        trendValue = average;
                        break;
                    }

                    const color = getScoreColor(
                      trendValue,
                      measurable.goal,
                      measurable.conditional,
                      measurable?.min_range,
                      measurable?.max_range,
                    );
                    return (
                      <tr
                        key={id}
                        className="cursor-pointer h-12"
                        onClick={() =>
                          router.push(`/company/${router.query.company}/team/${router.query.team}/scoreboard/${id}`)
                        }
                        draggable
                        onDragStart={() => (dragMeasurable.current = index)}
                        onDragEnter={() => (draggedOverMeasurable.current = index)}
                        onDragEnd={handleMeasurableSort}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <td>
                          <Image
                            src="/images/move-icons/drag.png"
                            className="-z-1 ml-3"
                            alt="New Feature"
                            height={20}
                            width={20}
                            style={{
                              maxWidth: '100%',
                              height: 'auto',
                            }}
                          />
                        </td>
                        {editable ? (
                          <td className="pl-4 w-8 py-3">
                            <input
                              type="checkbox"
                              checked={currentSelectedMeasurables?.includes(id)}
                              className="rounded cursor-pointer"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSelectedMeasurables([...selectedMeasurables, id]);
                                } else {
                                  setSelectedMeasurables(selectedMeasurables.filter((_id) => _id !== id));
                                }
                              }}
                            ></input>
                          </td>
                        ) : null}
                        <td>
                          <div className="flex whitespace-nowrap">
                            {user ? (
                              <>
                                {(profileData?.enabledWGProfile && wgSkills) ||
                                (profileData?.enabledVOPSProfile && vopsValue) ? (
                                  <Popover className="relative">
                                    <div>
                                      <Popover.Button
                                        ref={buttonRef}
                                        onMouseEnter={() => {
                                          setShowPopover(id);
                                        }}
                                        onMouseLeave={() => setShowPopover(null)}
                                      >
                                        <Avatar user={user} showName={true} />
                                      </Popover.Button>
                                      <Transition
                                        show={showPopover === id}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                            {profileData?.enabledWGProfile && wgSkills ? (
                                              <WGAssessment wgSkills={wgSkills} />
                                            ) : null}
                                            {profileData?.enabledVOPSProfile && vopsValue ? (
                                              <div className="text-black font-bold my-2 text-xs">
                                                <div className="mx-2 my-1">{vopsValue}</div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </Popover.Panel>
                                      </Transition>
                                    </div>
                                  </Popover>
                                ) : (
                                  <Avatar user={user} showName={true} />
                                )}
                              </>
                            ) : null}
                          </div>
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="truncate w-96">{title}</div>
                          </div>
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          <div className="flex items-center">
                            {conditional && conditional !== 'range' ? (
                              <span className="mr-1">{getConditional(conditional)?.label}</span>
                            ) : null}
                            {conditional !== 'range' ? getValueString(goal, type) : goalRange}
                          </div>
                        </td>
                        {showTrend ? (
                          <td className={`h-12 px-6 whitespace-nowrap ${color.bg}`}>
                            <div className={`flex items-center justify-end ${color.fg}`}>
                              {getValueString(trendValue, type)}
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        {data(measurable)}
                        <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                          {editable ? (
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {measurableActions.map(({ label, action }) => (
                                          <Menu.Item key={`${measurable.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();

                                                  action?.(measurable);
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {selectedMeasurables.length ? (
        <FloatingActionButtons hpos="center" vpos="bottom">
          <FloatingActionButton
            onClick={async () => {
              if (archiving) return;

              setArchiving(true);
              const { error: updateError } = await updateMeasurables({
                ids: selectedMeasurables,
                _set: {
                  archived: isArchivedList ? false : true,
                },
              });

              if (updateError) {
                setError(updateError);
                captureException(updateError);
              } else {
                await refreshData(router);
              }

              setSelectedMeasurables([]);
              setArchiving(false);
            }}
          >
            <div className="flex items-center justify-center w-14">
              {archiving ? <Loader color="text-white" /> : isArchivedList ? 'Unarchive' : 'Archive'}
            </div>
          </FloatingActionButton>
        </FloatingActionButtons>
      ) : null}
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
      <NewScoreDialog
        open={editingScore}
        setOpen={setEditingScore}
        score={scoreToEdit}
        week_start_on={week_start_on}
        setError={setError}
        setUpdated={setUpdated}
      />
    </>
  );
}
