import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_COMPANY_USERS = gql`
  query GetCompanyUsers($company_id: Int) {
    company_users(where: { company_id: { _eq: $company_id } }, order_by: { id: asc }) {
      id
      user_id
      leave_company
      wg_skills
      vops_skills
      other_assessments
      user {
        ...UserFragment
        accounts {
          id
          provider_type
        }
      }
      role {
        id
        name
      }
    }

    invites(where: { company_id: { _eq: $company_id } }) {
      id
      email
      role {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_COMPANY_USERS;
