import { PlusIcon } from '@heroicons/react/solid';
import { captureException } from '@sentry/nextjs';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardLayout,
  Header,
  MoveEntityDialog,
  RichTextViewer,
  CreateOpportunityDialog,
  CreateObjectiveDialog,
} from 'components';
import NewScoreDialog, { getIntervalDate } from 'components/NewScoreDialog/NewScoreDialog';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { GET_COMPANY } from 'data/company';
import { getConditional, getInterval, getType, GET_MEASURABLE, UPDATE_MEASURABLE } from 'data/measurables';
import { Measurable as MeasurableType } from 'data/measurables/types';
import { Score } from 'data/scores/types';
import {
  addMinutes,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfDay,
  subMonths,
  subQuarters,
  subWeeks,
  subDays,
  startOfYear,
  subYears,
  differenceInYears,
  isSameYear,
} from 'date-fns';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ReactElement, useState } from 'react';
import { serverClient } from 'src/urqlClient';
import { useMutation, useQuery } from 'urql';
import { classNames, getScoreColor, refreshData } from 'utils';

export function getScores(measurable: MeasurableType, numPeriods = 12): Score[] {
  const { scores, interval } = measurable;

  let scoreDate;
  let now = new Date();
  now = addMinutes(now, now.getTimezoneOffset());

  const groupedScores = {};
  let dates = [];

  switch (interval) {
    case 'monthly':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subMonths(startOfMonth(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfMonth(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'quarterly':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subQuarters(startOfQuarter(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfQuarter(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'daily':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subDays(startOfDay(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfDay(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'annually':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subYears(startOfYear(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfYear(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'weekly':
    default:
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subWeeks(startOfWeek(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfWeek(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
  }

  const filteredScores = [];
  Object.values(groupedScores).forEach((scoresForPeriod: Score[]) => {
    if (scoresForPeriod.length) {
      const mostRecentScore = scoresForPeriod.reduce(
        (mrs, score) => (isAfter(parseISO(score.updated_at), parseISO(mrs.updated_at)) ? score : mrs),
        scoresForPeriod[0],
      );
      filteredScores.push(mostRecentScore);
    }
  });

  return filteredScores;
}

export function getScoresByTimePeriod(measurable: MeasurableType, startDate: Date, endDate: Date): Score[] {
  const { scores, interval } = measurable;

  let scoreDate;

  const groupedScores = {};
  let dates = [];

  let noOfMonths = 0;
  let noOfQuarters = 0;
  let noOfDays = 0;
  let noOfWeeks = 0;
  let noOfYears = 0;

  switch (interval) {
    case 'monthly':
      noOfMonths = differenceInMonths(endDate, startDate) + 1;
      dates = Array(noOfMonths)
        .fill('')
        .map((_, i) => format(subMonths(startOfMonth(endDate), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfMonth(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'quarterly':
      noOfQuarters = differenceInQuarters(endDate, startDate) + 1;
      dates = Array(noOfQuarters)
        .fill('')
        .map((_, i) => format(subQuarters(startOfQuarter(endDate), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfQuarter(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'annually':
      noOfYears = differenceInYears(endDate, startDate) + 1;
      dates = Array(noOfYears)
        .fill('')
        .map((_, i) => format(subYears(startOfYear(endDate), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfYear(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'daily':
      noOfDays = differenceInDays(endDate, startDate) + 1;

      dates = Array(noOfDays)
        .fill('')
        .map((_, i) => format(subDays(startOfDay(endDate), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfDay(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'weekly':
    default:
      noOfWeeks = differenceInWeeks(endDate, startDate) + 1;

      dates = Array(noOfWeeks)
        .fill('')
        .map((_, i) => format(subWeeks(startOfWeek(endDate), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfWeek(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
  }

  const filteredScores = [];
  Object.values(groupedScores).forEach((scoresForPeriod: Score[]) => {
    if (scoresForPeriod.length) {
      const mostRecentScore = scoresForPeriod.reduce(
        (mrs, score) => (isAfter(parseISO(score.updated_at), parseISO(mrs.updated_at)) ? score : mrs),
        scoresForPeriod[0],
      );
      filteredScores.push(mostRecentScore);
    }
  });

  return filteredScores;
}

export function getValueString(value: number | string, type?: string): string {
  if (value === null) return null;

  switch (type) {
    case 'currency':
      return `${getType(type)?.symbol}${value.toLocaleString()}`;
    case 'percentage':
      return `${value.toLocaleString()}${getType(type)?.symbol}`;
    default:
      return value.toLocaleString();
  }
}

export function getTotal(measurable: MeasurableType): number {
  const filteredScores = getScores(measurable).filter((score) => score.value !== null);
  return filteredScores.length
    ? Math.round(filteredScores.reduce((accum, { value }) => accum + value, 0) * 100) / 100
    : null;
}

export function getTotalByTime(
  measurable: MeasurableType,
): { label: string; totalScore: number; averageScore: number }[] {
  const currentYear = new Date().getFullYear();
  const timePeriods = [
    { label: 'Q1', start: currentYear + '-01-01', end: currentYear + '-03-31' },
    { label: 'Q2', start: currentYear + '-04-01', end: currentYear + '-06-30' },
    { label: 'Q3', start: currentYear + '-07-01', end: currentYear + '-09-30' },
    { label: 'Q4', start: currentYear + '-10-01', end: currentYear + '-12-31' },
    { label: 'YTD', start: currentYear + '-01-01', end: currentYear + '-12-31' },
  ];

  // rewrite getTotal method to getScoresByTimePeriod for eachTimePeriod label
  const filteredScoresForTimePeriods = timePeriods.map((timePeriod) => {
    const startDate = parseISO(timePeriod.start);
    const endDate = parseISO(timePeriod.end);
    const scores = getScoresByTimePeriod(measurable, startDate, endDate);

    // Calculate the sum of totals
    const sumOfTotals = scores.reduce((accum, score) => accum + score.value, 0);
    const averageScore = scores.length > 0 ? sumOfTotals / scores.length : 0;

    return { label: timePeriod.label, totalScore: sumOfTotals, averageScore };
  });

  return filteredScoresForTimePeriods;
}

export function getAverage(measurable: MeasurableType): number {
  const total = getTotal(measurable);

  if (total === null) return null;

  const filteredScores = getScores(measurable).filter((score) => score.value !== null);
  const numScores = filteredScores.length;

  return total !== null && numScores ? Math.round((total / numScores) * 100) / 100 : null;
}

function getRange(measurable: MeasurableType): string {
  let now = new Date();
  now = addMinutes(now, now.getTimezoneOffset());

  let oldestDate = new Date();
  const filteredScores = getScores(measurable).filter((score) => score.value !== null);
  filteredScores.forEach(({ date }) => {
    let scoreDate = new Date(date);
    scoreDate = addMinutes(scoreDate, scoreDate.getTimezoneOffset());

    oldestDate = isBefore(scoreDate, oldestDate) ? scoreDate : oldestDate;
  });

  let difference: number;
  let range: string;
  switch (measurable.interval) {
    case 'monthly':
      difference = differenceInMonths(now, oldestDate) + 1;
      range = `${difference} month${difference === 1 ? '' : 's'}`;
      break;
    case 'quarterly':
      difference = differenceInQuarters(now, oldestDate) + 1;
      range = `${difference} quarter${difference === 1 ? '' : 's'}`;
      break;
    case 'annually':
      difference = differenceInYears(now, oldestDate) + 1;
      range = `${difference} years${difference === 1 ? '' : 's'}`;
      break;
    case 'daily':
      difference = differenceInDays(now, oldestDate) + 1;
      range = `${difference} day${difference === 1 ? '' : 's'}`;
      break;
    case 'weekly':
    default:
      difference = differenceInWeeks(now, oldestDate) + 1;
      range = `${difference} week${difference === 1 ? '' : 's'}`;
      break;
  }

  return range;
}

export function getTableScores(measurable: MeasurableType, more: number): { score?: Score; date: Date }[] {
  const totalNumber = 6 * (more + 1);
  let now = new Date();

  let subFn;
  let isSameFn;
  switch (measurable.interval) {
    case 'monthly':
      subFn = subMonths;
      now = startOfMonth(now);
      isSameFn = isSameMonth;
      break;
    case 'quarterly':
      subFn = subQuarters;
      now = startOfQuarter(now);
      isSameFn = isSameQuarter;
      break;
    case 'annually':
      subFn = subYears;
      now = startOfYear(now);
      isSameFn = isSameYear;
      break;
    case 'daily':
      subFn = subDays;
      now = startOfDay(now);
      isSameFn = isSameDay;
      break;
    case 'weekly':
    default:
      subFn = subWeeks;
      now = startOfWeek(now);
      isSameFn = isSameWeek;
      break;
  }

  const filteredScores = getScores(measurable, totalNumber);
  const scores: { score?: Score; date: Date }[] = [];
  for (let i = 0; i < totalNumber; ++i) {
    const date: Date = subFn(now, i);
    const score = filteredScores.find(({ date: scoreDate }) => {
      return isSameFn(date, parseISO(scoreDate));
    });

    if (score) {
      scores.push({ score, date });
    } else {
      scores.push({ date });
    }
  }

  return scores;
}

function MeasurableStats({
  measurable,
  week_start_on,
  setEditingScore,
  setScoreToEdit,
}: {
  measurable: MeasurableType;
  week_start_on: number;
  setEditingScore?: (bool: boolean) => void;
  setScoreToEdit: (score: { score: Score; measurable: MeasurableType; date: Date }) => void;
}): ReactElement {
  const total = getTotal(measurable);

  const totalByTime = getTotalByTime(measurable);

  const average = getAverage(measurable);
  const range = getRange(measurable);
  const [moreCount, setMoreCount] = useState(0);

  const scores = getTableScores(measurable, moreCount);

  const averageColor = getScoreColor(
    average,
    measurable.goal,
    measurable.conditional,
    measurable?.min_range,
    measurable?.max_range,
  );

  return (
    <>
      <div className="mt-4">
        <h3 className="text-sm leading-6 font-semibold text-gray-900">Last {range}</h3>
        <dl className="mt-4 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x">
          <div className="px-4 py-5 bg-white overflow-hidden sm:p-6">
            <dt className="text-md font-medium text-gray-500 truncate">Total</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{getValueString(total, measurable.type)}</dd>
          </div>
          <div className="px-4 py-5 bg-white overflow-hidden sm:p-6">
            <dt className="text-md font-medium text-gray-500 truncate">Average</dt>
            <dd className={`mt-1 text-3xl font-semibold ${averageColor.fg}`}>
              {getValueString(average, measurable.type)}
            </dd>
          </div>
        </dl>
      </div>
      {measurable.interval !== 'annually' ? (
        <>
          <h3 className="mt-4 text-sm leading-6 font-semibold text-gray-900">Aggregate Scores</h3>
          <div className="mt-4 mx-1 shadow border-b border-gray-200 sm:rounded-lg flex-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 w-full flex">
                <tr className="flex w-full text-gray-500">
                  <th scope="col" className="px-6 py-3 w-1/2 text-left font-medium text-xs uppercase tracking-wider">
                    Time Period
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/2 text-left font-medium text-xs uppercase tracking-wider">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/2 text-left font-medium text-xs uppercase tracking-wider">
                    Average
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 flex flex-col items-center w-full">
                <tr className="flex w-full">
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">Q1 - Jan-Mar</td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[0].totalScore, measurable.type)}
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[0].averageScore, measurable.type)}
                  </td>
                </tr>
                <tr className="flex w-full">
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">Q2 - Apr-June</td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[1].totalScore, measurable.type)}
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[1].averageScore, measurable.type)}
                  </td>
                </tr>
                <tr className="flex w-full">
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">
                    Q3 - July-Sept
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[2].totalScore, measurable.type)}
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[2].averageScore, measurable.type)}
                  </td>
                </tr>
                <tr className="flex w-full">
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">Q4 - Oct-Dec</td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[3].totalScore, measurable.type)}
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[3].averageScore, measurable.type)}
                  </td>
                </tr>
                <tr className="flex w-full">
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">Year to date</td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[4].totalScore, measurable.type)}
                  </td>
                  <td className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    {getValueString(totalByTime[4].averageScore, measurable.type)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : null}

      <h3 className="mt-4 text-sm leading-6 font-semibold text-gray-900">Scores</h3>
      <div className="mt-4 mx-1 shadow border-b border-gray-200 sm:rounded-lg flex-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 w-full flex">
            <tr className="flex w-full text-gray-500">
              <th scope="col" className="px-6 py-3 w-1/2 text-left font-medium text-xs uppercase tracking-wider">
                Date
              </th>
              <th scope="col" className="px-6 py-3 w-1/2 text-left font-medium text-xs uppercase tracking-wider">
                Score
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 flex flex-col items-center w-full">
            {scores.map(({ score, date }) => {
              const color = getScoreColor(
                score?.value,
                measurable.goal,
                measurable.conditional,
                measurable?.min_range,
                measurable?.max_range,
              );
              const updateScore = (event) => {
                event.stopPropagation();

                if (setEditingScore) {
                  setEditingScore(true);
                  setScoreToEdit({ score, measurable, date });
                }
              };
              return (
                <tr key={date.toDateString()} className={classNames(color ? color.bg : '', 'flex w-full')}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm w-1/2 font-medium text-gray-900">
                    {getIntervalDate(measurable.interval, 0, date, week_start_on)?.strVal}
                  </td>
                  <td key={date.toString()} className="h-12 py-4 px-6 whitespace-nowrap text-sm w-1/2 text-gray-700">
                    <div className="flex items-center justify-start">
                      {score?.value || score?.value === 0 ? (
                        <div className="flex flex-1 items-center justify-between gap-x-1">
                          {getValueString(score.value, measurable.type)}
                          {setEditingScore ? (
                            <span
                              onClick={updateScore}
                              className={classNames(setEditingScore ? 'cursor-pointer' : '', 'text-primary')}
                            >
                              Update
                            </span>
                          ) : null}
                        </div>
                      ) : setEditingScore ? (
                        <div
                          className={classNames(
                            setEditingScore ? 'cursor-pointer' : '',
                            'flex items-center text-primary gap-x-1',
                          )}
                          onClick={updateScore}
                        >
                          <PlusIcon className="h-4 w-4 stroke-2" />
                          <span className="text-sm mt-0.5">Add Score</span>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-center w-full">
        <button
          className="flex justify-center mt-4 border border-gray-300 text-gray-700 px-3 py-1 rounded-md cursor-pointer"
          onClick={() => setMoreCount(moreCount + 1)}
        >
          View More
        </button>
      </div>
    </>
  );
}

export default function Measurable({
  measurable,
  expired,
}: {
  measurable: MeasurableType;
  expired: boolean;
}): ReactElement {
  const router = useRouter();

  const [result] = useQuery({
    query: GET_COMPANY,
    variables: { id: router.query.company },
  });

  const week_start_on = result.data?.companies?.[0]?.week_start_on;

  const [editingScore, setEditingScore] = useState(false);
  const [scoreToEdit, setScoreToEdit] = useState(null);
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [moveEntity, setMoveEntity] = useState<MeasurableType>();
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [, updateMeasurable] = useMutation(UPDATE_MEASURABLE);

  const [createMeasurableObjective, setCreateMeasurableObjective] =
    useState<{ title: string; description: string; measurable_id: number }>();
  const [objectiveDialogOpen, setObjectiveDialogOpen] = useState(false);

  const [createMeasurableOpportunity, setCreateMeasurableOpportunity] =
    useState<{ title: string; description: string; measurable_id: number }>();
  const [opportunityDialogOpen, setOpportunityDialogOpen] = useState(false);

  const editable = expired === false;

  const min = getValueString(measurable?.min_range, measurable?.type);
  const max = getValueString(measurable?.max_range, measurable?.type);
  const goalRange = min && max ? min + ' - ' + max : null;

  const options = [
    {
      label: 'Edit',
      action: () => {
        router.push(`/company/${router.query.company}/team/${router.query.team}/scoreboard/${measurable.id}/edit`);
      },
    },

    {
      label: 'Send to Another Team',
      action: () => {
        setMoveEntity(measurable);
        setMoveDialogOpen(true);
      },
    },
    {
      label: 'Create Objective',
      action: () => {
        setCreateMeasurableObjective({
          title: measurable?.title,
          description: measurable?.description,
          measurable_id: measurable?.id,
        });
        setObjectiveDialogOpen(true);
      },
    },
    {
      label: 'Create Opportunity',
      action: () => {
        setCreateMeasurableOpportunity({
          title: measurable?.title,
          description: measurable?.description,
          measurable_id: measurable?.id,
        });
        setOpportunityDialogOpen(true);
      },
    },
  ];

  if (!measurable?.archived) {
    options.push({
      label: 'Archive',

      action: async () => {
        const { error: archiveError } = await updateMeasurable({
          _set: {
            archived: true,
          },
          id: measurable.id,
        });

        if (archiveError) {
          setError(archiveError);
          captureException(archiveError);
        } else {
          setUpdated('Measurable Archived');
          refreshData(router);
        }
        router.push(
          `/company/${router.query.company}/team/${router.query.team}/scoreboard?interval=${measurable?.interval}`,
        );
      },
    });
  }

  return (
    <>
      <Header
        title={measurable.title}
        crumbs={[
          {
            label: 'Scoreboard',
            href: `/company/${router.query.company}/team/${router.query.team}/scoreboard`,
          },
        ]}
      />
      <CardLayout>
        <Card>
          <CardHeader title={measurable.title} options={editable ? options : []} />
          <CardContent>
            <div className="flex gap-x-8">
              {measurable.type ? (
                <div className="flex flex-col gap-y-2">
                  <span className="text-sm font-semibold text-gray-500">Type</span>
                  <div className="flex flex-1 items-center text-sm justify-self-end">
                    {getInterval(measurable.interval)?.label}
                  </div>
                </div>
              ) : null}
              {measurable.user ? (
                <div className="flex flex-col gap-y-2">
                  <span className="text-sm font-semibold text-gray-500">Assignee</span>
                  <div className="flex flex-1 items-center text-sm justify-self-end">
                    <Avatar user={measurable.user} showName={true} />
                  </div>
                </div>
              ) : null}
              {measurable.goal ||
              ((measurable?.min_range || measurable?.min_range === 0) &&
                (measurable.max_range || measurable.max_range === 0)) ? (
                <div className="flex flex-col gap-y-2">
                  <span className="text-sm font-semibold text-gray-500">Goal</span>
                  <div className="flex flex-1 items-center text-sm justify-self-end">
                    {measurable.conditional && measurable.conditional !== 'range' ? (
                      <span className="mr-1">{getConditional(measurable.conditional)?.label}</span>
                    ) : null}
                    {measurable.conditional !== 'range' ? getValueString(measurable.goal, measurable.type) : goalRange}
                  </div>
                </div>
              ) : null}
            </div>
            {measurable.description ? (
              <div className="mt-6">
                <span className="text-sm font-semibold text-gray-500">Description</span>
                <div className="mt-2 text-sm">
                  <RichTextViewer text={measurable.description} />
                </div>
              </div>
            ) : null}
            <MeasurableStats
              measurable={measurable}
              week_start_on={week_start_on}
              setEditingScore={editable ? setEditingScore : null}
              setScoreToEdit={setScoreToEdit}
            />
          </CardContent>
        </Card>
      </CardLayout>
      <MoveEntityDialog
        entityType="measurable"
        originalEntity={moveEntity}
        open={moveDialogOpen}
        setOpen={setMoveDialogOpen}
      />
      <CreateObjectiveDialog
        createObjective={createMeasurableObjective}
        open={objectiveDialogOpen}
        setOpen={setObjectiveDialogOpen}
      />
      <CreateOpportunityDialog
        createOpportunity={createMeasurableOpportunity}
        open={opportunityDialogOpen}
        setOpen={setOpportunityDialogOpen}
      />

      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
      <NewScoreDialog
        open={editingScore}
        setOpen={setEditingScore}
        score={scoreToEdit}
        week_start_on={week_start_on}
        setError={setError}
        setUpdated={setUpdated}
      />
    </>
  );
}

Measurable.auth = true;
Measurable.company = true;

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<{ session?: Session; measurable: MeasurableType }>> {
  const session = await getSession(context);

  const client = serverClient(session?.token as unknown as string);
  const measurableResult = await client.query(GET_MEASURABLE, { id: context.query.measurable }).toPromise();
  const measurable = measurableResult.data?.measurables?.[0];

  if (!measurable) {
    return {
      redirect: {
        destination: `/company/${context.query.company}/team/${context.query.team}/scoreboard`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      session,
      measurable,
    },
  };
}
