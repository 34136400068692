import { gql } from 'urql';
import { MEASURABLE_FRAGMENT } from './fragments';

const GET_MEASURABLE = gql`
  query GetMeasurable($id: Int!) {
    measurables(where: { id: { _eq: $id } }) {
      ...MeasurableFragment
    }
  }
  ${MEASURABLE_FRAGMENT}
`;

export default GET_MEASURABLE;
