import { gql } from 'urql';
import { MEASURABLE_FRAGMENT } from './fragments';

const GET_MEASURABLES = gql`
  query GetMeasurables($where: measurables_bool_exp!) {
    measurables(where: $where, order_by: [{ sort_order: asc }, { owner: asc }, { title: asc }]) {
      ...MeasurableFragment
    }
  }
  ${MEASURABLE_FRAGMENT}
`;

export default GET_MEASURABLES;
