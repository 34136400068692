import { captureException } from '@sentry/nextjs';
import { Menu, Transition, Popover } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, Loader, SuccessToast, WGAssessment, FullScreenLoader } from 'components';
import { FloatingActionButton, FloatingActionButtons } from 'components/FloatingActionButton';
import { UPDATE_ACTIONS, UPDATE_ACTION, SortStatus, SortFields } from 'data/actions';
import { Action as ActionType } from 'data/actions/types';
import { endOfDay, isBefore, parseISO } from 'date-fns';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useMemo, useState, useRef } from 'react';
import { useMutation, useQuery } from 'urql';
import { classNames, refreshData, gqlArray } from 'utils';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';
import { CompanyUser } from 'components/UsersList/UsersList';
import { Action } from 'data/actions/types';
import ActionForm from 'components/ActionForm';
import { Opportunity } from 'data/opportunities/types';
import button from 'styles/button';
import { ADD_ACTIONS } from 'data/actions';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { Objective } from 'data/objectives/types';
import { GET_ACTION_FILES } from 'data/actions';
import { FileAttachment, FileStatus } from 'types/file-attachment';
import GET_WEBHOOKS_BY_USER_AND_TYPE from 'data/actions/get-webhooks-by-user-and-type';
import notifyZapier, { Webhook } from 'utils/notifyWebhooks';
import { extractText } from 'utils/extractSerializedString';

type ActionsListProps = {
  actions: ActionType[];
  actionActions?: {
    label: string;
    action: (action: ActionType) => void;
  }[];
  editable?: boolean;
  isArchivedList?: boolean;
  teamMember?: { id?: number; selected_actions?: number[]; action_sort_field?: string; action_sort_order?: string };
  users?: CompanyUser[];
  opportunities?: Opportunity[];
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
  objectives?: Objective[];
};

export default function ActionsList({
  actions,
  actionActions,
  editable,
  isArchivedList,
  teamMember,
  users,
  opportunities,
  profileData,
  objectives,
}: ActionsListProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  let checkedActions = teamMember?.selected_actions || [];
  const actionList = useMemo(() => {
    return actions.map(({ id }) => id);
  }, [actions]);
  if (checkedActions.length > 0) {
    checkedActions = checkedActions.filter((id) => actionList.includes(id));
  }
  const [selectedActions, setSelectedActions] = useState<number[]>(checkedActions);
  const [selectedUser, setSelectedUser] = useState(null);

  const [, updateActions] = useMutation(UPDATE_ACTIONS);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [completing, setCompleting] = useState<boolean>(false);
  const [reopening, setReopening] = useState<boolean>(false);
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const [, updateAction] = useMutation(UPDATE_ACTION);
  const [, addActions] = useMutation(ADD_ACTIONS);

  let checksAreCompleted;

  if (selectedActions?.length) {
    const firstAction = actions.find(({ id }) => id === selectedActions[0]);

    checksAreCompleted = firstAction?.completed;
  }

  const { complete, incomplete } = useMemo(() => {
    const _incomplete = [];
    const _complete = [];
    actions.forEach(({ id, completed }) => {
      if (completed) _complete.push(id);
      else _incomplete.push(id);
    });

    return { complete: _complete, incomplete: _incomplete };
  }, [actions]);

  const handleSelectedUser = (selected) => {
    setSelectedUser(selected?.user?.name);
  };

  const [actionCompleteWebhooks, refetchActionCompleteWebhooks] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: session.id,
      trigger_type: 'action-complete',
    },
  });

  // When clicking Select All, only actions that match the current selection's compeleted status should be selected
  // Or, if none are selected, select only incomplete actions
  const selectableActions = useMemo(() => {
    if (incomplete.length && (!checksAreCompleted || !complete.length)) return incomplete;
    if (complete.length && (checksAreCompleted || !incomplete.length)) return complete;
  }, [checksAreCompleted, incomplete, complete]);

  const [currentSort, setCurrentSort] = useState(teamMember?.action_sort_order || SortStatus.Default);
  const [sortField, setSortField] = useState(teamMember?.action_sort_field || SortFields.Date);

  const sortedActions = useMemo(() => {
    const sortableActions = [...actions];
    const prop = sortField.split('.');
    const len = prop.length;
    const nonSortableActionIds = [];

    // Add null value records (based on the sort field) at the end of the list
    const nonSortableActions = sortableActions.filter((action) => {
      let i = 1;
      let data = action[prop[0]];
      while (i <= len) {
        if (data === null || data === undefined) {
          nonSortableActionIds.push(action.id);
          return action;
        } else {
          if (i < len) {
            data = data[prop[i]];
          }
        }
        i++;
      }
    });

    const formatedActions = sortableActions
      .filter((obj) => !nonSortableActionIds.includes(obj.id))
      // eslint-disable-next-line
      .sort((action: any, prevAction: any) => {
        let i = 0;
        while (i < len) {
          action = action[prop[i]];
          prevAction = prevAction[prop[i]];
          i++;
        }
        if (currentSort === SortStatus.Up) {
          return sortField === SortFields.Date
            ? new Date(action)?.getTime() - new Date(prevAction)?.getTime()
            : action?.localeCompare(prevAction);
        }
        if (currentSort === SortStatus.Down) {
          return sortField === SortFields.Date
            ? new Date(prevAction)?.getTime() - new Date(action)?.getTime()
            : prevAction?.localeCompare(action);
        }
        return 0;
      });

    return formatedActions.concat(nonSortableActions);
  }, [actions, currentSort, sortField]);

  const sortIcons = {
    up: <ChevronUpIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    down: <ChevronDownIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    default: null,
  };

  async function onSortChange(field: string) {
    let nextSort: string;
    currentSort === SortStatus.Down ? (nextSort = SortStatus.Up) : (nextSort = SortStatus.Down);
    setCurrentSort(nextSort);
    setSortField(field);
    await updateTeamMember({
      id: teamMember?.id,
      set: {
        action_sort_field: field,
        action_sort_order: nextSort,
      },
    });
  }

  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [form, setForm] = useState<Action>(null);
  const [saving, setSaving] = useState(false);

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  const [commentSectionOpen, setCommentSectionOpen] = useState<boolean>(false);

  const [filesResult, refetchFiles] = useQuery({
    query: GET_ACTION_FILES,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
        status: { _eq: FileStatus.Complete },
      },
    },
    pause: !form?.id,
  });
  const actionFiles: FileAttachment[] = filesResult.data?.action_files || [];

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {editable ? (
                    <th scope="col" className="pl-4 pr-4 w-8">
                      <input
                        type="checkbox"
                        checked={selectableActions?.length > 0 && selectedActions?.length === selectableActions.length}
                        className="rounded cursor-pointer"
                        onChange={async (event) => {
                          let _selectedActions = [];
                          if (event.target.checked) {
                            _selectedActions = selectableActions || [];
                            setSelectedActions(_selectedActions);
                          } else {
                            setSelectedActions(_selectedActions);
                          }
                          // Remember checked actions
                          if (!isArchivedList) {
                            await updateTeamMember({
                              id: teamMember?.id,
                              set: {
                                selected_actions: gqlArray(_selectedActions),
                              },
                            });
                          }
                        }}
                      ></input>
                    </th>
                  ) : null}
                  <th
                    scope="col"
                    className="pl-2 pr-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Date)}
                    >
                      <div className="flex">
                        Date
                        <span>{sortField === SortFields.Date ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Assignee)}
                    >
                      <div className="flex">
                        Assignee
                        <span>{sortField === SortFields.Assignee ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  {actionActions?.length ? (
                    <th scope="col" className="relative px-6 py-3 w-16">
                      <span className="sr-only">Edit</span>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {sortedActions.map((action) => {
                  const { id, title, user, date, completed, objectiveByObjective } = action;
                  const wgSkills = users.find((_user) => _user.user_id === user?.id)?.wg_skills;
                  const vopsSkills = users.find((_user) => _user.user_id === user?.id)?.vops_skills;
                  const vopsValue = getVOPSValue(vopsSkills);

                  const disabled = (completed && checksAreCompleted === false) || (!completed && checksAreCompleted);
                  let dateColor = 'text-gray-900';
                  if (completed) {
                    dateColor = 'text-gray-400';
                  } else if (date && isBefore(endOfDay(parseISO(date)), new Date())) {
                    dateColor = 'text-red-500';
                  }
                  return (
                    <Fragment key={id}>
                      <tr key={id} className={classNames(completed ? 'strikeout bg-gray-50' : '')}>
                        {editable ? (
                          <td className="pl-4 pr-4 w-8 py-3 relative">
                            <input
                              type="checkbox"
                              checked={selectedActions.includes(id)}
                              className={classNames(
                                disabled ? 'border-gray-400 bg-gray-100 cursor-not-allowed' : '',
                                'rounded cursor-pointer',
                              )}
                              disabled={disabled}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={async (event) => {
                                let _selectedActions = [];
                                if (event.target.checked) {
                                  _selectedActions = [...selectedActions, id];
                                  setSelectedActions(_selectedActions);
                                } else {
                                  _selectedActions = selectedActions.filter((_id) => _id !== id);
                                  setSelectedActions(_selectedActions);
                                }
                                // Remember checked actions
                                if (!isArchivedList) {
                                  await updateTeamMember({
                                    id: teamMember?.id,
                                    set: {
                                      selected_actions: gqlArray(_selectedActions),
                                    },
                                  });
                                }
                              }}
                            ></input>
                          </td>
                        ) : null}
                        <td
                          className={classNames(
                            completed ? 'text-gray-400 italic' : '',
                            'h-12 pl-2 pr-6 whitespace-nowrap relative strikeable',
                          )}
                        >
                          <div className="flex items-center">
                            <div
                              className={classNames(editable ? 'cursor-pointer' : '', 'truncate w-96')}
                              onClick={() => {
                                if (!editable) {
                                  return false;
                                }

                                /* eslint-disable */
                                const {
                                  user: userData,
                                  opportunityByOpportunity,
                                  objectiveByObjective,
                                  ...actionForm
                                } = action;
                                /* eslint-enable */
                                setActiveAction(!activeAction);
                                setForm({ ...actionForm, assignees: [] });
                                setCommentSectionOpen(false);
                              }}
                            >
                              <div className="flex flex-row">
                                {`${title}`}
                                {objectiveByObjective && (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      router.push(
                                        `/company/${router.query.company}/team/${router.query.team}/objectives/${objectiveByObjective?.id}`,
                                      );
                                    }}
                                    className="flex items-center cursor-pointer text-gray-500 hover:text-primary-700"
                                  >
                                    <LightningBoltIcon className="h-4 w-4 my-1 ml-2  rounded" />
                                    <span className="text-xs">
                                      {' '}
                                      Objective Attached -{' '}
                                      {objectiveByObjective.title.length > 23
                                        ? `${objectiveByObjective.title.substring(0, 23)}...`
                                        : objectiveByObjective.title}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          className={classNames(
                            dateColor,
                            completed ? 'italic' : '',
                            'h-12 px-6 whitespace-nowrap relative strikeable',
                          )}
                        >
                          {date}
                        </td>
                        <td
                          className={classNames(
                            completed ? 'text-gray-400 italic' : '',
                            'h-12 px-6 whitespace-nowrap relative strikeable',
                          )}
                        >
                          {user ? (
                            <div className="flex">
                              {(profileData?.enabledWGProfile && wgSkills) ||
                              (profileData?.enabledVOPSProfile && vopsValue) ? (
                                <Popover className="relative">
                                  <div>
                                    <Popover.Button
                                      ref={buttonRef}
                                      onMouseEnter={() => {
                                        setShowPopover(id);
                                      }}
                                      onMouseLeave={() => setShowPopover(null)}
                                    >
                                      <Avatar user={user} showName={true} />
                                    </Popover.Button>
                                    <Transition
                                      show={showPopover === id}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                          {profileData?.enabledWGProfile && wgSkills ? (
                                            <WGAssessment wgSkills={wgSkills} />
                                          ) : null}
                                          {profileData?.enabledVOPSProfile && vopsValue ? (
                                            <div className="text-black font-bold my-2 text-xs">
                                              <div className="mx-2 my-1">{vopsValue}</div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </div>
                                </Popover>
                              ) : (
                                <Avatar user={user} showName={true} />
                              )}
                            </div>
                          ) : null}
                        </td>
                        {actionActions?.length ? (
                          <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200 relative">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 mt-24 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {actionActions.map(({ label, action: _action }) => (
                                          <Menu.Item key={`${action.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();

                                                  // Add comments
                                                  if (label === 'Add Comments') {
                                                    const { ...actionForm } = action;
                                                    setActiveAction(!activeAction);
                                                    setForm({ ...actionForm, assignees: [] });
                                                    setCommentSectionOpen(true);
                                                  }

                                                  _action?.(action);
                                                  // Update checked actions when user archive a checked action
                                                  if (
                                                    !isArchivedList &&
                                                    label === 'Archive' &&
                                                    selectedActions.includes(id)
                                                  ) {
                                                    await updateTeamMember({
                                                      id: teamMember?.id,
                                                      set: {
                                                        selected_actions: gqlArray(
                                                          selectedActions.filter((_id) => _id !== id),
                                                        ),
                                                      },
                                                    });
                                                  }
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </td>
                        ) : null}
                      </tr>
                      {activeAction && form?.id === action.id ? (
                        <tr className="bg-gray-100">
                          <td colSpan={5}>
                            <div className="shadow rounded-lg bg-white">
                              <div className="flex-1 mt-4 h-max">
                                <ActionForm
                                  form={form}
                                  setForm={setForm}
                                  users={users}
                                  opportunities={opportunities}
                                  commentSectionOpen={commentSectionOpen}
                                  setCommentSectionOpen={setCommentSectionOpen}
                                  objectives={objectives}
                                  actionFiles={actionFiles}
                                  refetchFiles={refetchFiles}
                                  handleUserSelection={handleSelectedUser}
                                />
                              </div>
                              <div className="flex mb-4 justify-end">
                                <button
                                  type="button"
                                  className={classNames(
                                    button.white(),
                                    'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                  )}
                                  onClick={() => {
                                    setActiveAction(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                {!form.archived && !commentSectionOpen ? (
                                  <button
                                    type="button"
                                    className={classNames(
                                      button.white(),
                                      'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                    )}
                                    onClick={async () => {
                                      setCommentSectionOpen(true);
                                    }}
                                  >
                                    View Comments
                                  </button>
                                ) : null}
                                <button
                                  type="button"
                                  className={classNames(
                                    button.primary(),
                                    'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-6',
                                  )}
                                  onClick={async () => {
                                    setSaving(true);
                                    delete form['action_comments'];
                                    delete form['action_files'];
                                    const assignees = form?.assignees || [];
                                    delete form['assignees'];
                                    const actionListData = [];
                                    const { error: editError } = await updateAction({
                                      _set: {
                                        ...form,
                                      },
                                      id: form?.id,
                                      activity: 'update',
                                      timestamp: Date.now(),
                                      userId: session.id,
                                    });

                                    // Format comments for multiple assignees
                                    const comments = [];
                                    action?.action_comments?.reverse().map((comment) => {
                                      comments.push({
                                        comment: comment?.comment,
                                        user_id: comment?.user_id,
                                        timestamp: comment?.timestamp,
                                      });
                                    });

                                    // Copy updated action to multiple assignees
                                    assignees.forEach((assignee) => {
                                      const copiedAction = {
                                        ...form,
                                        assignee: assignee?.user_id,
                                        company_id: router.query.company,
                                        teams_id: router.query.team,
                                        action_comments: { data: comments },
                                        actions_activities: {
                                          data: {
                                            activity: 'insert',
                                            user_id: session.id,
                                            timestamp: Date.now(),
                                          },
                                        },
                                        action_files: {
                                          data:
                                            actionFiles?.length > 0
                                              ? actionFiles?.map((file) => {
                                                  return {
                                                    url: file.url,
                                                    file_name: file.file_name,
                                                    file_type: file.file_type,
                                                    status: file.status,
                                                  };
                                                })
                                              : [],
                                        },
                                      };
                                      delete copiedAction['id'];
                                      actionListData.push(copiedAction);
                                    });

                                    const { error: addError } = await addActions({
                                      objects: actionListData,
                                    });
                                    if (editError) {
                                      setError(editError);
                                      captureException(editError);
                                    } else if (addError) {
                                      setError(addError);
                                      captureException(addError);
                                    } else {
                                      await refreshData(router);
                                      setSuccess('Action updated');
                                      setActiveAction(false);
                                    }

                                    setSaving(false);
                                  }}
                                >
                                  {saving ? <Loader color="text-primary" /> : 'Save'}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedActions?.length ? (
        <FloatingActionButtons hpos="center" vpos="bottom">
          <FloatingActionButton
            onClick={async () => {
              if (archiving) return;

              setArchiving(true);
              const now = Date.now();
              const { error: updateError } = await updateActions({
                ids: selectedActions,
                _set: {
                  archived: isArchivedList ? false : true,
                },
                activityObjects: selectedActions.map((id) => ({
                  entity_id: id,
                  activity: isArchivedList ? 'update' : 'archive',
                  timestamp: now,
                  user_id: session.id,
                })),
              });

              if (updateError) {
                setError(updateError);
                captureException(updateError);
              } else {
                setSuccess('Actions '.concat(isArchivedList ? 'Unarchived' : 'Archived'));
                await refreshData(router);
              }

              setSelectedActions([]);
              setArchiving(false);
              // Update checked actions
              if (!isArchivedList) {
                updateTeamMember({
                  id: teamMember?.id,
                  set: {
                    selected_actions: gqlArray([]),
                  },
                });
              }
            }}
          >
            <div className="flex items-center justify-center w-14">
              {archiving ? <Loader color="text-white" /> : isArchivedList ? 'Unarchive' : 'Archive'}
            </div>
          </FloatingActionButton>
          {checksAreCompleted === false ? (
            <FloatingActionButton
              onClick={async () => {
                if (completing) return;
                if (form?.id && selectedActions?.includes(form?.id)) {
                  setForm({ ...form, completed: true });
                }
                setCompleting(true);
                const now = Date.now();
                const { error: updateError } = await updateActions({
                  ids: selectedActions,
                  _set: {
                    completed: true,
                  },
                  activityObjects: selectedActions.map((id) => ({
                    entity_id: id,
                    activity: 'complete',
                    timestamp: now,
                    user_id: session.id,
                  })),
                });

                const selectedActionDetails = actions.find((ac) => {
                  return ac.id === selectableActions[0];
                });

                console.log({ selectableActions });
                console.log({ selectedActionDetails });
                console.log({ actions });

                if (actionCompleteWebhooks.data && actionCompleteWebhooks.data?.zapier_webhooks.length > 0) {
                  const wh: Webhook[] = actionCompleteWebhooks.data?.zapier_webhooks;
                  const actionRequestBody = {
                    title: selectedActionDetails?.title,
                    description: extractText(selectedActionDetails?.description) || '',
                    owner: selectedActionDetails?.user?.name || '',
                    date: selectedActionDetails?.date,
                    completed: 'true',
                  };

                  const res = await notifyZapier(wh, actionRequestBody);
                }

                if (updateError) {
                  setError(updateError);
                  captureException(updateError);
                } else {
                  await refreshData(router);
                }

                setSelectedActions([]);
                setCompleting(false);
                // Update checked actions
                if (!isArchivedList) {
                  updateTeamMember({
                    id: teamMember?.id,
                    set: {
                      selected_actions: gqlArray([]),
                    },
                  });
                }
              }}
            >
              <div className="flex items-center justify-center w-20">
                {completing ? <Loader color="text-white" /> : 'Complete'}
              </div>
            </FloatingActionButton>
          ) : null}
          {checksAreCompleted ? (
            <FloatingActionButton
              onClick={async () => {
                if (reopening) return;
                if (form?.id && selectedActions?.includes(form?.id)) {
                  setForm({ ...form, completed: false });
                }
                setReopening(true);
                const now = Date.now();
                const { error: updateError } = await updateActions({
                  ids: selectedActions,
                  _set: {
                    completed: false,
                  },
                  activityObjects: selectedActions.map((id) => ({
                    entity_id: id,
                    activity: 'update',
                    timestamp: now,
                    user_id: session.id,
                  })),
                });

                if (updateError) {
                  setError(updateError);
                  captureException(updateError);
                } else {
                  await refreshData(router);
                }

                setSelectedActions([]);
                setReopening(false);
                // Update checked actions
                if (!isArchivedList) {
                  updateTeamMember({
                    id: teamMember?.id,
                    set: {
                      selected_actions: gqlArray([]),
                    },
                  });
                }
              }}
            >
              <div className="flex items-center justify-center w-20">
                {reopening ? <Loader color="text-white" /> : 'Reopen'}
              </div>
            </FloatingActionButton>
          ) : null}
        </FloatingActionButtons>
      ) : null}
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={success} setMessage={setSuccess} />
      <FullScreenLoader color="text-primary" background="bg-primary" open={saving} />
    </>
  );
}
