import { gql } from 'urql';
import { MEETING_PARTICIPANT_FRAGMENT } from './fragments';

export default gql`
  mutation UpdateMeetingParticipant($_set: meeting_participants_set_input, $id: Int!) {
    update_meeting_participants_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...MeetingParticipantFragment
    }
  }
  ${MEETING_PARTICIPANT_FRAGMENT}
`;

const RATINGS = [1, 1.5, 2, 2.5, 3, 3.5, 4];

export { RATINGS };
